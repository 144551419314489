








































import Vue, { PropType } from "vue";
import { MatchingJob } from "@/store/modules/candidates/interfaces";
import { get_job_icon } from "@/utils/jobs_icons";
import { JobList } from "@/interfaces/candidate/candidate_dashboard/job_list";
import { get_color_by_matching_score } from "@/utils/colors";
import { format_overall_score } from "@/utils/global";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "JobList",
  data(): JobList {
    return {
      font_color: "",
      background_color: "",
      color_code: ""
    };
  },
  mounted() {
    const color = get_color_by_matching_score(this.job?.score); // Get random color
    this.font_color = `color: ${color}`;
    this.background_color = `background: ${color}`;
    this.color_code = color;
  },
  props: {
    job: {
      type: Object as PropType<MatchingJob>,
      required: true
    }
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  methods: {
    /**
     * Extract job icon path from job title
     * @return string => job icon path
     */
    get_icon(title: string): string {
      return get_job_icon(title);
    },
    /**
     * Update svg path fill color and return svg
     * @param icon
     * @return HTMLElement => updated svg
     */
    transform_job_icon(icon: HTMLElement): HTMLElement {
      for (let node of icon.children)
        node.setAttribute("fill", this.color_code);
      return icon;
    },
    format_score(score: string): number {
      return format_overall_score(score);
    },
    view_job(job_id: number) {
      sessionStorage.removeItem("job_id_next_move_assessment");
      sessionStorage.removeItem("next_move_job_details");
      this.$router.push(`jobs/view/${job_id}`);
    }
  }
});
