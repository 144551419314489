































































































import Vue from "vue";
import { CareerPath } from "@/interfaces/candidate/candidate_dashboard/career_path";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { CAREER_PATH } from "@/store/modules/candidates/constants";
import { SET_CAREER_PATH, USER_PROFILE } from "@/store/modules/auth/constants";
import { get_random_color } from "@/utils/colors";
import { generate_random_key } from "@/utils/global";
import { get_random_career_path_icon } from "@/utils/career_path";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";

export default Vue.extend({
  name: "CareerPath",
  data(): CareerPath {
    return {
      plus_icon: require("@/assets/icons/linear/plus.svg"),
      arrow_icon: require("@/assets/icons/direction-right.svg"),
      career_path: [],
      loading: false
    };
  },
  computed: {
    ...mapGetters("auth", {
      get_user_profile: USER_PROFILE
    }),
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  async mounted() {
    this.loading = true;
    // IF user profile exist => fetch career path
    if (!this.get_user_profile.profile.career_path?.length) {
      const response = await this.fetch_career_path(
        this.get_user_profile.info.file_id
      );
      if (response)
        await this.set_career_path(response.top_jobs[0]?.career_path);
      else await this.set_career_path(null);
    }

    const career_paths = this.get_user_profile.profile.career_path;
    this.career_path = career_paths.map((path: string) => {
      const color = get_random_color();
      const icon = get_random_career_path_icon();
      return {
        title: path,
        font_color: `color: ${color}`,
        background_color: `background: ${color}`,
        border_color: `border-color: ${color}`,
        color_code: color,
        icon,
        transform_source: (icon: SVGElement): SVGElement => {
          for (const node of icon.children) node.setAttribute("fill", color);
          return icon;
        }
      };
    });
    this.loading = false;
  },
  methods: {
    generate_random_key,
    ...mapActions("candidate", {
      fetch_career_path: CAREER_PATH
    }),
    ...mapMutations("auth", {
      set_career_path: SET_CAREER_PATH
    })
  }
});
