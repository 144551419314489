const CAREER_PATH_ICONS: string[] = [
  require("@/assets/icons/bold/cloud-connection.svg"),
  require("@/assets/icons/bold/layer.svg"),
  require("@/assets/icons/bold/pen-tool-2.svg"),
  require("@/assets/icons/bold/simcard.svg"),
  require("@/assets/icons/bold/user-octagon.svg")
];

/**
 * Return random career path icon
 * @return string: Career path icon string
 */
export function get_random_career_path_icon(): string {
  const length = CAREER_PATH_ICONS.length;
  const number = Math.floor(Math.random() * length + 1);
  return CAREER_PATH_ICONS[number - 1];
}
