



















































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  CANDIDATE_JOBS,
  GET_MATCHING_JOBS,
  TOP_MATCHING_JOBS
} from "@/store/modules/candidates/constants";
import { MatchingJobs } from "@/interfaces/candidate/candidate_dashboard/matching_jobs";
import JobList from "@/components/candidate/candidate_jobs/JobList.vue";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";

export default Vue.extend({
  name: "MatchingJobs",
  components: { DataNotFound, JobList },
  data(): MatchingJobs {
    return {
      matching_jobs: []
    };
  },
  async mounted() {
    if (
      this.candidate_jobs.top_matching_jobs.results.length === 0 &&
      !this.candidate_jobs.top_matching_jobs.loading
    ) {
      this.set_top_matching_job({ loading: true, results: [], total: 0 });
      const response = await this.fetch_matching_jobs({
        candidate_id: this.user_details.id,
        page: 0,
        limit: 10
      });
      if (response) {
        this.matching_jobs = response.results.slice(0, 6);
        this.set_top_matching_job({
          loading: false,
          results: response.results,
          total: response.total
        });
      }
    } else if (!this.candidate_jobs.top_matching_jobs.loading)
      this.matching_jobs = this.candidate_jobs.top_matching_jobs.results.slice(
        0,
        6
      );
  },
  computed: {
    ...mapGetters("candidate", {
      candidate_jobs: CANDIDATE_JOBS
    }),
    ...mapGetters("auth", {
      user_details: GET_USER_DETAILS
    })
  },
  methods: {
    ...mapActions("candidate", {
      fetch_matching_jobs: GET_MATCHING_JOBS
    }),
    ...mapMutations("candidate", {
      set_top_matching_job: TOP_MATCHING_JOBS
    }),
    getTitle(): string {
      return this.$t("shared.no-matching-job").toString();
    }
  }
});
