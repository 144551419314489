















































































































































































































































































































































































import Vue from "vue";
import { mapGetters } from "vuex";
import {
  ASSESSMENT_QUESTIONS,
  TENANT_INFO,
  TENANT_INFO_LOADING
} from "@/store/modules/candidates/constants";
import ByPrincipleUsLogo from "@/components/assessments/ByPrincipleUsLogo.vue";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { Assessments } from "@/interfaces/candidate/candidate_dashboard/assessment_test";
import { AppRoutes } from "@/interfaces/";
export default Vue.extend({
  name: "AssessmentTests",
  components: { ByPrincipleUsLogo },
  data() {
    return {
      assessments: [] as Assessments[]
    };
  },
  mounted() {
    this.intialize_assesments();
  },
  computed: {
    AppRoutes() {
      return AppRoutes;
    },
    ...mapGetters("candidate", {
      get_assessment_questions: ASSESSMENT_QUESTIONS,
      get_assessment_loading: TENANT_INFO_LOADING,
      tenant_info: TENANT_INFO
    }),
    SiteDirections() {
      return SiteDirections;
    },
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    })
  },
  watch: {
    get_site_direction() {
      this.intialize_assesments();
    }
  },
  methods: {
    on_click(link: string) {
      window.location.href = link;
    },
    intialize_assesments() {
      this.assessments = [
        {
          title: this.$t("assessments.type.culture-add").toString(),
          level: this.$t("assessments.type.entry-level").toString(),
          time: this.$t("assessments.type.time").toString(),
          type: this.$t("assessments.type.personality-culture").toString(),
          logo: require("@/assets/assesments/assessment.svg"),
          link: "https://www.testgorilla.com/test-library/personality-culture-tests/culture-add-test/",
          description: this.$t(
            "assessments.type.culture-add-description"
          ).toString()
        },
        {
          title: this.$t("assessments.type.communication-skills").toString(),
          level: this.$t("assessments.type.intermediate").toString(),
          time: this.$t("assessments.type.time").toString(),
          type: this.$t("assessments.type.situational-judgment").toString(),
          logo: require("@/assets/assesments/assessment.svg"),
          link: "https://www.testgorilla.com/test-library/situational-judgment-tests/communication-test/",
          description: this.$t(
            "assessments.type.communication-skills-description"
          ).toString()
        },
        {
          title: this.$t("assessments.type.problem-solving").toString(),
          level: this.$t("assessments.type.intermediate").toString(),
          time: this.$t("assessments.type.time").toString(),
          type: this.$t("assessments.type.cognitive-ability").toString(),
          logo: require("@/assets/assesments/assessment.svg"),
          link: "https://www.testgorilla.com/test-library/cognitive-ability-tests/problem-solving-test/",
          description: this.$t(
            "assessments.type.problem-solving-description"
          ).toString()
        },
        {
          title: this.$t("assessments.type.critical-thinking").toString(),
          level: this.$t("assessments.type.advance").toString(),
          time: this.$t("assessments.type.time").toString(),
          type: this.$t("assessments.type.cognitive-ability").toString(),
          logo: require("@/assets/assesments/assessment.svg"),
          link: "https://www.testgorilla.com/test-library/cognitive-ability-tests/critical-thinking-test/",
          description: this.$t(
            "assessments.type.critical-thinking-description"
          ).toString()
        }
      ];
    }
  }
});
